<template>
  <div>
    <head-top-other :title="title"></head-top-other>
    <div class="page">
      <div class="wrap">
        <div class="line-1">
          <div class="box-1">
            <div class="icon-1"></div>
            <div :class="['text',currentStep >= 1 ? 'active' : '']">输入账号</div>
          </div>
          <div class="icon-arrow"></div>
          <div class="box-1">
            <div class="icon-2"></div>
            <div :class="['text',currentStep >= 2 ? 'active' : '']">账号验证</div>
          </div>
          <div class="icon-arrow"></div>
          <div class="box-1">
            <div class="icon-3"></div>
            <div :class="['text',currentStep >= 3 ? 'active' : '']">重置密码</div>
          </div>
          <div class="icon-arrow"></div>
          <div class="box-1">
            <div class="icon-4"></div>
            <div :class="['text',currentStep >= 4 ? 'active' : '']">成功找回</div>
          </div>
        </div>
        <div class="line-2">
          <div class="step-1" v-if="currentStep === 1">
            <div class="line-2-1">
              <!-- <div
                :class="['line-2-1-item', index === 0 ? 'active' : '']"
                @click="loginTabChange(0)"
              >
                邮箱
              </div> -->
              <div
                :class="['line-2-1-item', index === 1 ? 'active' : '']"
                @click="loginTabChange(1)"
              >
                手机
              </div>
            </div>
            <el-form
              key="email"
              :model="emailForm"
              ref="emailForm"
              :rules="emailRules"
              v-if="index === 0"
            >
              <el-form-item prop="email">
                <el-input
                  v-model="emailForm.email"
                  autocomplete="off"
                  placeholder="请输入邮箱"
                ></el-input>
              </el-form-item>
              <el-form-item prop="codeEmail">
                <el-input
                  class="code"
                  v-model="emailForm.codeEmail"
                  autocomplete="off"
                  placeholder="验证码"
                ></el-input>
              </el-form-item>
              <el-button class="submit" @click="onSubmit('emailForm')"
                >下一步</el-button
              >
            </el-form>

            <el-form
              key="tel"
              :model="telForm"
              ref="telForm"
              :rules="telRules"
              v-else
            >
              <el-form-item prop="tel">
                <el-input
                  v-model="telForm.tel"
                  autocomplete="off"
                  placeholder="请输入手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item prop="codeTel" class="input_code">
                <el-input
                  class="code"
                  v-model="telForm.codeTel"
                  autocomplete="off"
                  placeholder="验证码"
                ></el-input>
                <div @click="refreshCode" class="check_code">
                  <identify :identifyCode="identifyCode"></identify>
                </div>
              </el-form-item>
              <el-button class="submit" @click="onSubmit('telForm')"
                >下一步</el-button
              >
            </el-form>
          </div>
          <div class="step-2" v-if="currentStep === 2">
            <el-form :model="checkForm" ref="checkForm" :rules="checkRules">
              <el-form-item prop="checkCode">
                <el-input
                  v-model="checkForm.checkCode"
                  autocomplete="off"
                  placeholder="请输入验证码"
                ></el-input>
              </el-form-item>
              <el-button class="submit" @click="onSubmit('checkForm')"
                >下一步</el-button
              >
            </el-form>
          </div>
          <div class="step-3" v-if="currentStep === 3">
            <el-form
              :model="passwordForm"
              ref="passwordForm"
              :rules="passwordRules"
            >
              <el-form-item prop="password">
                <el-input
                  type="password"
                  v-model="passwordForm.password"
                  autocomplete="off"
                  placeholder="密码"
                ></el-input>
              </el-form-item>
              <el-form-item prop="rePassword">
                <el-input
                  type="password"
                  v-model="passwordForm.rePassword"
                  autocomplete="off"
                  placeholder="再次输入密码"
                ></el-input>
              </el-form-item>
              <el-button class="submit" @click="onSubmit('passwordForm')"
                >下一步</el-button
              >
            </el-form>
          </div>
          <div class="step-4" v-if="currentStep === 4">
            <span>密码修改成功</span>
            <router-link class="submit" :to="{path: '/'}">回到首页</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HeadTopOther from "@/components/HeadTopOther";
  import identify from "./components/identify.vue";
  export default {
    name: "ForgetPassword",
    data() {
      return {
        title: "忘记密码",
        index: 1,
        currentStep: 1,
        emailForm: {
          email: "",
          codeEmail: ""
        },
        emailRules: {
          email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
          codeEmail: [
            { required: true, message: "请输入验证码", trigger: "blur" }
          ]
        },
        telForm: {
          tel: "",
          codeTel: ""
        },
        telRules: {
          tel: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
          codeTel: [{ required: true, message: "请输入验证码", trigger: "blur" }]
        },
        checkForm: {
          checkCode: ""
        },
        checkRules: {
          checkCode: [
            { required: true, message: "请输入验证码", trigger: "blur" }
          ]
        },
        passwordForm: {
          password: "",
          rePassword: ""
        },
        passwordRules: {
          password: [{ required: true, message: "请输入密码", trigger: "blur" }],
          rePassword: [
            { required: true, message: "请输入再次密码", trigger: "blur" }
          ]
        },
        identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz", //随机串内容,从这里随机抽几个显示验证码
        identifyCode: "", //验证码图片内容
      };
    },
    components: {
      HeadTopOther, identify
    },
    mounted() {
      // 初始化验证码
      this.identifyCode = "";
      //参数：（1）随机串内容。（2）验证码显示位数
      this.makeCode(this.identifyCodes, 4);
    },
    methods: {
      loginTabChange(index) {
        this.index = index;
      },
      onSubmit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            console.log("confirm", this[form].codeTel);
            console.log("验证码:", this.identifyCode);
            if (this[form].codeTel != this.identifyCode) {
              this.$message({
                message: "验证码输入错误",
                type: 'error',
              })
              return false;
            }

            if (this.currentStep < 4) {
              this.currentStep++;
            }
          } else {
            return false;
          }
        });
      },
      // 重置验证码
      refreshCode() {
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
      },
      //获取验证码的值
      makeCode(o, l) {
        for (let i = 0; i < l; i++) {
          //通过循环获取字符串内随机几位
          this.identifyCode +=
            this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
        }
      },
      //随机数字：用于当角标拿字符串的值
      randomNum(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      }
    }
  };
</script>

<style lang="scss" scoped>
.page {
  background-color: #eeeeee;
  padding: 20px 0;
  .wrap {
    background-color: #ffffff;
    padding: 50px 0 90px;
    text-align: left;
    .box-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .text {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 26px;
      &.active {
        font-weight: bold;
        color: rgba(111, 15, 149, 1);
      }
    }
    .line-1 {
      width: 800px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .icon-1 {
        width: 56px;
        height: 56px;
        background: url(../../assets/index/icon-027.png) no-repeat;
      }
      .icon-arrow {
        width: 30px;
        height: 30px;
        background: url(../../assets/index/icon-026.png) no-repeat;
      }
      .icon-2 {
        width: 56px;
        height: 56px;
        background: url(../../assets/index/icon-025.png) no-repeat;
      }
      .icon-3 {
        width: 56px;
        height: 56px;
        background: url(../../assets/index/icon-024.png) no-repeat;
      }
      .icon-4 {
        width: 56px;
        height: 56px;
        background: url(../../assets/index/icon-023.png) no-repeat;
      }
    }
    .line-2 {
      width: 320px;
      margin: 0 auto;
      .line-2-1 {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        margin-top: 80px;
        &-item {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 26px;
          width: 35px;
          margin: 0 18px;
          &.active {
            color: rgba(111, 15, 149, 1);
            border-bottom: 3px solid rgba(111, 15, 149, 1);
          }
        }
      }
    }
    .code {
      width: 192px;
    }
    .input_code {
      :deep(.el-form-item__content) {
        display: flex;
      }
      .check_code {
        margin-left: 10px;
      }
    }
    .submit {
      width: 320px;
      height: 40px;
      background: rgba(111, 15, 149, 1);
      border-radius: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
      text-align: center;
      padding: 0;
      display: inline-block;
    }
    .step-2,
    .step-3,
    .step-4 {
      margin: 100px 0 20px;
    }
    .step-4 {
      span {
        display: block;
        text-align: center;
        font-size: 26px;
        color: rgba(111, 15, 149, 1);
        margin-bottom: 20px;
      }
    }
  }
}
</style>
