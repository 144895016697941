<template>
  <div>
    <div class="body">
      <div class="main">
        <div class="mian-img">
          <router-link to="/">
            <el-image :src="require('@/assets/index/logo.png')"></el-image>
          </router-link>
        </div>

        <div class="box-info">
          <span class="title">{{ title }}</span>

          <a href="" class="a-nav">联系电话：0033787819090</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadTopOther',
  components: {},
  data() {
    return {};
  },
  props: { title: { type: String, required: true } },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.body {
  background-color: #5f0e8e;
  display: flex;
  justify-content: center;

  .main {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: center;
    .mian-img {
      width: 120px;
      height: auto;
      :deep(.el-image) {
        padding: 20px;
      }
    }
  }

  .box-info {
    display: flex;
    justify-content: space-between;
    flex: 1;
    font-size: 16px;
    color: rgba(102, 102, 102, 1);
    margin-top: 46px;
    .title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      margin-left: 20px;
    }
    a {
      padding: 0 16px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 30px;
      &::before {
        content: '';
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        background: url(../assets/index/icon-022.png) center/contain no-repeat;
      }
    }
  }
}
</style>